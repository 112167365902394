import { Grid } from '@mui/material/';
import { selectRehabActions } from '@Selectors/Rehab.selectors';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { deleteRehabAction } from '@Actions';

import {
	Button,
	error,
	MenuListButton,
	openDrawer,
	StylovyzeTable,
	useSettings,
	WarningDialog,
	getCurrency,
} from '@innovyze/stylovyze';
import { Node } from 'react-flow-renderer';
import { StylovyzeHeader } from '@innovyze/lib-am-common';
import { NODE_TYPES, Option, ReactFlowSchema, RehabAction } from '@Types';
import { Add, Edit, Delete } from '@mui/icons-material';
import { StyledGrid } from '@Utils/styles';
import { useGlobalization } from '@Translations';

export interface RehabActionListProps {
	viewerAccess: boolean;
	isLoading: boolean;
	actionTableCells: string[][];
	onRehabActionChange: (rehabAction: RehabAction | null) => void;
	nodes: Node[];
}

const RehabActionList = ({
	viewerAccess,
	isLoading,
	actionTableCells,
	onRehabActionChange,
	nodes,
}: RehabActionListProps) => {
	const { t } = useGlobalization();
	const dispatch = useDispatch();
	const rehabActions = selectRehabActions();
	const { companySettings } = useSettings();

	const [deleteIndex, setDeleteIndex] = useState(-1);
	// const handleRehabActionChange = useCallback(
	// 	event => {
	// 		onRehabActionChange(event?.target.value);
	// 	},
	// 	[onRehabActionChange],
	// );

	const rowMenuItems = (index?: number) => {
		const result = new Array<Option>();
		result.push({
			text: t('Edit'),
			name: 'Edit',
			icon: <Edit />,
			onClick: () => {
				if (
					index != null &&
					index != undefined &&
					rehabActions &&
					rehabActions.length > index &&
					onRehabActionChange
				) {
					onRehabActionChange(rehabActions[index]);
					dispatch(openDrawer('addNewActionDrawer'));
				}
			},
			cy: `${index}-dropdown-edit`,
		});
		result.push({
			text: t('Delete'),
			name: 'Delete',
			icon: <Delete />,
			onClick: () => {
				if (
					index != null &&
					index != undefined &&
					rehabActions &&
					rehabActions.length > index
				) {
					setDeleteIndex(index);
				}
			},
			cy: `${index}-dropdown-delete`,
		});

		return result;
	};

	const menuCell = (row: string[], index: number) => {
		if (!viewerAccess) {
			return (
				<div
					role="menu"
					tabIndex={0}
					className={isLoading ? 'hidden' : ''}>
					<MenuListButton menuItems={rowMenuItems(index)} />
				</div>
			);
		}
	};

	const headerReactNodes = (): StylovyzeHeader[] => {
		const header = new Array<StylovyzeHeader>();
		header.push(
			{
				key: 'name',
				cell: t('Name'),
				sortable: false,
				align: 'left',
				maxWidth: '200px',
			},
			{
				key: 'unitCost',
				cell: t('Unit Cost'),
				sortable: false,
				align: 'left',
			},
			{
				key: 'unit',
				cell: t('Cost Unit'),
				sortable: false,
				align: 'left',
			},
			{
				key: 'notes',
				cell: t('Notes'),
				sortable: false,
				align: 'left',
			},
		);

		header.push({
			key: 'menu',
			cell: '',
			sortable: false,
			width: '68px',
			align: 'center',
			padding: '0 10px',
		});

		return header;
	};

	const rowReactNodes = (row: string[], index: number): React.ReactNode[] => {
		return row.map((cell, nCol) => {
			switch (nCol) {
				case 1:
					return getCurrency(+cell, companySettings);
				case 4:
					return menuCell(row, index);
				default:
					return cell;
			}
		});
	};

	const getTitleArea = () => {
		return (
			<StyledGrid
				alignItems="center"
				justifyContent="flex-end"
				container
				style={{ width: '70%' }}
				spacing={1}>
				{!viewerAccess ? (
					<Grid
						item
						alignItems="center"
						justifyContent="flex-end"
						data-cy="grid-actionbutton"
						wrap="nowrap">
						<Button
							startIcon={<Add />}
							disabled={isLoading}
							variant="contained"
							onClick={() => {
								if (onRehabActionChange)
									onRehabActionChange(null);
								dispatch(openDrawer('addNewActionDrawer'));
							}}>
							{t('Action')}
						</Button>
					</Grid>
				) : (
					[]
				)}
			</StyledGrid>
		);
	};

	const checkActionExist = (action: RehabAction): boolean => {
		const node = nodes.find(node => {
			const reactNode = node as ReactFlowSchema;
			if (reactNode.type == NODE_TYPES.action) {
				if (reactNode.data && reactNode.data.label == action.actionId) {
					return true;
				}
			}

			return false;
		});

		if (node) return true;
		else return false;
	};

	return (
		<>
			<WarningDialog
				open={deleteIndex != -1}
				title={t('Delete Action?')}
				content={t('Are you sure you want to delete Action?')}
				cancelText={t('Cancel')}
				confirmText={t('Delete')}
				onCancel={() => setDeleteIndex(-1)}
				onConfirm={() => {
					if (!checkActionExist(rehabActions[deleteIndex])) {
						dispatch(
							deleteRehabAction(rehabActions[deleteIndex]._id),
						);
						setDeleteIndex(-1);
					} else {
						dispatch(
							error(
								t(
									'Failed to delete action. Action cannot be deleted if it is being used in tree.',
								),
							),
						);
						setDeleteIndex(-1);
					}
				}}
			/>
			<StylovyzeTable
				className={isLoading ? 'wait' : 'ScrollTable'}
				title={t('Actions')}
				titleArea={getTitleArea()}
				dataCy="actions-table"
				headers={headerReactNodes()}
				rows={
					actionTableCells.map((row: string[], nRow: number) => ({
						key: nRow,
						cells: rowReactNodes(row, nRow),
					})) || []
				}
				useSkeleton={{ rowCount: 5 }}
			/>
		</>
	);
};
export default RehabActionList;
