import { put, retry, takeLatest } from '@redux-saga/core/effects';
import { AnyAction } from 'redux';
import { getRehabRulesChoiceListApi } from '@Services/ChoiceList/ChoiceList.services';
import {
	RehabQueryChoiceList,
	RehabRuleChoiceListFiles,
} from '@Types/rehab.types';
import {
	getAllRehabRuleChoiceListFiles,
	getAllRehabRuleChoiceListFilesRejected,
	getAllRehabRuleChoiceListFilesResolved,
} from '@Actions/ChoiceList.action';
import { InspectionStandard } from '@Types';
import {
	getPACPDomainValues,
	getWSA05DomainValues,
} from '@innovyze/lib-am-common/Components';

import _ from 'lodash-es';

function* getAllRehabRuleChoiceListFilesSaga(action: AnyAction) {
	try {
		// const isSanitarySewer = action.payload.systemType === 'Sanitary Sewer';
		// const records = [
		// 	RECORD_TYPE_ENDPOINT.ASSET_ATTRIBUTES,
		// 	RECORD_TYPE_ENDPOINT.RISK,
		// 	RECORD_TYPE_ENDPOINT.USER_DEFINED_FIELDS,
		// ];
		// if (isSanitarySewer) {
		// 	records.push(
		// 		RECORD_TYPE_ENDPOINT.INSPECTION,
		// 		RECORD_TYPE_ENDPOINT.CONDITION,
		// 		RECORD_TYPE_ENDPOINT.DEFECT_FILTER,
		// 	);
		// }

		// const rehabChoiceLists: { [key: string]: RehabQueryChoiceList } = {};

		// for (const record of records) {
		// 	rehabChoiceLists[record] = yield retry(
		// 		3,
		// 		30000,
		// 		getRehabRulesChoiceListApi,
		// 		action.payload.systemType,
		// 		action.payload.assetType,
		// 		record,
		// 		action.payload.inspectionStandard,
		// 	);
		// }

		const rehabChoiceLists: { [key: string]: RehabQueryChoiceList } =
			yield retry(
				3,
				30000,
				getRehabRulesChoiceListApi,
				action.payload.systemType,
				action.payload.assetType,
				'All',
				action.payload.inspectionStandard,
			);

		const {
			asset,
			userdefined: udt,
			riskComponents,
			inspection,
			condition,
			defect,
			tasks,
		} = rehabChoiceLists?.data;

		inspection?.map((ele: any) => {
			if ('fieldOptions' in ele) {
				let domainValues: any = {};
				const keyCamelCase = _.camelCase(ele['key']);
				if (
					action.payload.inspectionStandard == InspectionStandard.PACP
				) {
					if (keyCamelCase == 'shape')
						domainValues = getPACPDomainValues(
							action.payload.t,
							'pipeShape',
						);
					else {
						domainValues = getPACPDomainValues(
							action.payload.t,
							_.camelCase(ele['key']),
						);
					}
				} else if (
					action.payload.inspectionStandard ==
					InspectionStandard.WSA05
				) {
					if (keyCamelCase == 'conduitLocationRiskFactor')
						domainValues = getWSA05DomainValues(
							action.payload.t,
							'conduitLocationRiskFactorObservedByOperator',
						);
					else {
						domainValues = getWSA05DomainValues(
							action.payload.t,
							_.camelCase(ele['key']),
						);
					}
				}
				if (!_.isEmpty(domainValues)) {
					const newOptions = Object.keys(domainValues).map(
						(ele: string) => {
							return {
								display_name: `${ele} - ${domainValues[ele]}`,
								value: ele,
							};
						},
					);
					ele['fieldOptions'] = newOptions;
				}
			}
		});

		const payload: RehabRuleChoiceListFiles = {
			assetRecordType:
				asset?.sort((a: { key: string }, b: { key: string }) =>
					a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
				) || [],
			inspectionRecordType:
				inspection?.sort((a: { key: string }, b: { key: string }) =>
					a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
				) || [],
			riskRecordType:
				riskComponents?.sort((a: { key: string }, b: { key: string }) =>
					a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
				) || [],
			conditionRecordType:
				condition?.sort((a: { key: string }, b: { key: string }) =>
					a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
				) || [],
			udtRecordType:
				udt?.sort((a: { key: string }, b: { key: string }) =>
					a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
				) || [],
			defectFilterRecordType:
				defect?.sort((a: { key: string }, b: { key: string }) =>
					a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
				) || [],
			tasksRecordType:
				tasks?.sort((a: { key: string }, b: { key: string }) =>
					a.key < b.key ? -1 : a.key > b.key ? 1 : 0,
				) || [],
		};

		yield put(getAllRehabRuleChoiceListFilesResolved(payload));
	} catch (e) {
		yield put(getAllRehabRuleChoiceListFilesRejected());
	}
}
function* watchGetAllRehabRuleChoiceListFiles() {
	yield takeLatest(
		getAllRehabRuleChoiceListFiles,
		getAllRehabRuleChoiceListFilesSaga,
	);
}

const sagaArray = [watchGetAllRehabRuleChoiceListFiles()];

export default sagaArray;
