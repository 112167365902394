import {
	Column as RiskDetailsColumn,
	headerReactNodes as rehabResultHeaderReactNodes,
} from './rehabResultPagination';
import { FilterStoreState } from '@innovyze/lib-am-common';
import {
	filterByIdSelect,
	filterByMultipleIdSelect,
} from '@innovyze/lib-am-common/Reducers';
import { t } from 'i18next';

export const rehabResultInitialState = (): FilterStoreState => {
	const headers = rehabResultHeaderReactNodes('Metric', t);
	const draftRehabAction = headers[RiskDetailsColumn.DraftRehabAction];
	const finalRehabAction = headers[RiskDetailsColumn.FinalRehabAction];

	return {
		query: '',
		filters: {},
		selects: [
			//Supposely this filter is for final rehab action, but for now draft rehab is easier, we keep the key to be final action
			{
				column: {
					id: finalRehabAction.key,
					caption: draftRehabAction.cell,
				},
				options: [],
				dataCy: finalRehabAction.key,
				filterBy: filterByMultipleIdSelect,
				value: [],
				multiple: true,
			},
			{
				column: {
					id: 'LockedOnly',
					caption: 'Only show locked assets',
				},
				options: [
					{ id: 'true', caption: t('True') },
					{ id: 'false', caption: t('False') },
				],
				dataCy: 'LockedOnly',
				filterBy: filterByIdSelect,
				value: undefined,
			},
		].reduce((d, v) => ({ ...d, [v.column.id]: v }), {}),
	};
};
