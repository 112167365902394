import * as AM_COMMON from '@innovyze/lib-am-common/Reducers';

import { createSelector } from '@reduxjs/toolkit';
import { moduleNamespace } from '@Store/config.store';
import { RehabCard } from '@Types/rehab.types';
import { NamespacedStoreState } from '@Types/store.types';
import { useSelector } from 'react-redux';
import { mapRowToCells } from '../reducers/rehabResultPagination';
import { Row, SelectProps } from '@innovyze/lib-am-common';

const parseNamespace = (state: NamespacedStoreState) => state[moduleNamespace];

const rehabStoreSelector = createSelector(
	parseNamespace,
	state => state.RehabStore,
);

export const CurrentTreeSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.currentTreeId,
);

const getReactFlowTreesSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.reactFlowDecisionTrees,
);
export const getReactFlowTreeSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.fullRehabTree,
);
export const selectorNewTreeQuery = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.newDecisionTree,
);
const selectorIsLoading = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.isLoading,
);

const getNewTreeNodesSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.newDecisionTree.treeNodes,
);

const getEdgeConnector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.connectorObject,
);

export const getRehabActionsSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabActions,
);

const RehabTabSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.tab,
);

const selectorRehabResultPagination = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabResult?.pagination,
);

export const selectorRehabResultRows = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabResult?.rows,
);

const selectorPaginationQuery = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabResult?.query,
);

const selectorFilterQuery = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.dropdownFilters.query,
);

export const searchQuerySelector = createSelector(
	rehabStoreSelector,
	rehabStore =>
		rehabStore.search.length
			? `&search=${encodeURIComponent(rehabStore.search)}`
			: '',
);

export const rehabResultQuerySelector = createSelector(
	rehabStoreSelector,
	selectorPaginationQuery,
	selectorFilterQuery,
	searchQuerySelector,
	(rehabStore, pag, filters, search) => {
		let q = pag;
		const rehabResult = rehabStore.rehabResult;
		if (filters.length) {
			q = q.length ? q + filters : `?${filters.slice(1)}`;
		}
		if (search.length) {
			q = q.length ? q + search : `?${search.slice(1)}`;
		}
		if (rehabResult.scanId) {
			q = q.length
				? q + `&scanId=${rehabResult.scanId}`
				: `?scanId=${rehabResult.scanId}`;
		}
		// console.log(q);
		return q;
	},
);

const selectorRehabResultTablePage = (system: string) =>
	createSelector(
		[selectorRehabResultPagination, selectorRehabResultRows],
		(pagination, rows) => ({
			pages: pagination.pages,
			page: pagination.page,
			cells: rows.map((r: Row) => mapRowToCells(r, system)),
			rowIds: rows.map((r: Row) => r?._id || ''),
			sortKey: pagination.sortKey,
			sortDescending: pagination.sortDescending,
			total: pagination.total,
		}),
	);

const selectorCanReload = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabResult?.pagination?.total > 0,
);

export const selectorRehabResultsPagination = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabResult.pagination,
);

const selectorRehabResultSlice = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabResult.pagination.slice,
);

const selectorRehabResultIsLoading = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabResult.isLoading,
);

const selectorRehabResultIsWaiting = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabResult.isWaiting,
);

const selectorRehabResultSearch = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.search,
);

const selectorRehabResultOnlyShowLocked = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.onlyShowLocked,
);

const selectorFilterProps = createSelector(
	rehabStoreSelector,
	rehabStore =>
		Object.values(rehabStore.dropdownFilters.selects) as SelectProps[],
);

const selectorRehabTreeValidationResult = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTreeValidation,
);

export const currentStartNodeIdSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.startNodeId,
);

const selectorRehabCost = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabCost,
);

const selectorIsCostLoading = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.isCostLoading,
);

export const selectorRehabTreesPagination = createSelector(
	rehabStoreSelector,
	state => state.rehabTrees.pagination,
);

export const rehabTreesSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTrees.cards as RehabCard[],
);

const selectorRehabTreesCanReload = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTrees.pagination.total > 0,
);

export const selectorRehabTreesPaginationQuery = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTrees.query,
);

export const rehabTreesStatusSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTrees.status,
);

const selectorRehabTreesSlice = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTrees.pagination.slice,
);

const selectorRehabTreesIsLoading = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTrees.isLoading,
);

const selectorRehabTreesIsWaiting = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTrees.isWaiting,
);

const selectorRehabTreesNameError = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTreeNameError,
);

const selectorRehabTreesIsCreating = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.isCreating,
);

const selectorRehabTreesIsDeleting = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.isDeleting,
);

const selectorRehabTreesIsRenaming = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.isRenaming,
);

const selectorRehabTreesPage = () => {
	const pagination = selectorRehabTreesPagination;
	const cards = rehabTreesSelector;

	return createSelector(pagination, cards, (pagination, cards) => ({
		total: pagination.total,
		pages: pagination.pages,
		page: pagination.page,
		cards: cards,
		sortKey: pagination.sortKey,
		sortDescending: pagination.sortDescending,
	}));
};

const rehabTreesListSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabTreesList,
);

const rehabTreesIsLoadingSelector = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.isRehabTreesListLoading,
);

const selectorRefreshList = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.refreshList,
);

export const selectorAllRiskConfigsIds = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.allRiskConfigModels,
);

const selectorAddNewActionToTree = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.rehabActionToAdd,
);

const selectorGetRehabTreeStatus = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.fullRehabTree.status,
);

export const selectGetRehabTreeStatus = () =>
	useSelector(selectorGetRehabTreeStatus);

const selectorGetRehabTreeValidationStatus = createSelector(
	rehabStoreSelector,
	rehabStore => rehabStore.fullRehabTree.validationStatus,
);

export const selectGetRehabTreeValidationStatus = () =>
	useSelector(selectorGetRehabTreeValidationStatus);

export const selectTreeValidationResult = () =>
	useSelector(selectorRehabTreeValidationResult);

export const selectNodesForNewTree = () => useSelector(getNewTreeNodesSelector);
export const selectCurrentTree = () => useSelector(getReactFlowTreeSelector);
export const selectCurrentTreeId = () => useSelector(CurrentTreeSelector);
export const selectIsLoading = () => useSelector(selectorIsLoading);
export const selectReactFlowTrees = () =>
	useSelector(getReactFlowTreesSelector);

export const selectRehabStore = () => useSelector(rehabStoreSelector);
export const edgeConnector = () => useSelector(getEdgeConnector);

export const selectRehabActions = () => useSelector(getRehabActionsSelector);

export const selectorRowsPerPage = (dispatchSliceFn: (slice: number) => void) =>
	createSelector(selectorRehabResultSlice, slice =>
		AM_COMMON.rowsPerPage(slice || 10, dispatchSliceFn),
	);

export const selectCanReload = () => useSelector(selectorCanReload);

export const selectRehabTab = () => useSelector(RehabTabSelector);

export const selectRehabResultQuery = () =>
	useSelector(rehabResultQuerySelector);

export const selectRehabResultTablePage = (system: string) =>
	useSelector(selectorRehabResultTablePage(system));

export const selectRowsPerPage = (dispatchSliceFn: (slice: number) => void) =>
	useSelector(selectorRowsPerPage(dispatchSliceFn));
export const selectRehabResultIsLoading = () =>
	useSelector(selectorRehabResultIsLoading);
export const selectRehabResultIsWaiting = () =>
	useSelector(selectorRehabResultIsWaiting);
export const selectRehabResultSearch = () =>
	useSelector(selectorRehabResultSearch);
export const selectRehabResultFilterProps = () =>
	useSelector(selectorFilterProps);
export const selectRehabResultOnlyShowLocked = () =>
	useSelector(selectorRehabResultOnlyShowLocked);
export const selectRehabResultsPagination = () =>
	useSelector(selectorRehabResultsPagination);

export const selectRehabResultRows = () => useSelector(selectorRehabResultRows);
export const startNodeIdSelector = () =>
	useSelector(currentStartNodeIdSelector);

export const selectRehabCost = () => useSelector(selectorRehabCost);

export const selectIsCostLoading = () => useSelector(selectorIsCostLoading);

export const selectRehabTrees = () => useSelector(rehabTreesSelector);
export const selectRehabTreesStatus = () =>
	useSelector(rehabTreesStatusSelector);

export const selectorRehabTreesPerPage = (
	dispatchSliceFn: (slice: number) => void,
) =>
	createSelector(selectorRehabTreesSlice, slice =>
		AM_COMMON.rowsPerPage(slice || 10, dispatchSliceFn),
	);

export const selectRehabTreesPerPage = (
	dispatchSliceFn: (slice: number) => void,
) => useSelector(selectorRehabTreesPerPage(dispatchSliceFn));
export const selectRehabTreesCanReload = () =>
	useSelector(selectorRehabTreesCanReload);
export const selectRehabTreesPage = () => useSelector(selectorRehabTreesPage());
export const selectRehabTreesIsLoading = () =>
	useSelector(selectorRehabTreesIsLoading);
export const selectRehabTreesIsWaiting = () =>
	useSelector(selectorRehabTreesIsWaiting);
export const selectRehabTreesNameError = () =>
	useSelector(selectorRehabTreesNameError);
export const selectRehabTreesIsCreating = () =>
	useSelector(selectorRehabTreesIsCreating);
export const selectRehabTreesIsDeleting = () =>
	useSelector(selectorRehabTreesIsDeleting);
export const selectRehabTreesPagination = () =>
	useSelector(selectorRehabTreesPagination);
export const selectRehabTreeIsRenaming = () =>
	useSelector(selectorRehabTreesIsRenaming);

export const selectRehabTreesList = () => useSelector(rehabTreesListSelector);
export const selectRehabTreesListIsLoading = () =>
	useSelector(rehabTreesIsLoadingSelector);

export const selectRefreshList = () => useSelector(selectorRefreshList);

export const selectAddNewActionToTree = () =>
	useSelector(selectorAddNewActionToTree);
