import rehabApi from '@Apis/rehab.api';
import { ResolvedResponse } from '@innovyze/lib-am-common';
import {
	AddRehabTreePayload,
	GetRehabTreeParams,
	RehabAction,
	RehabActionResolvedResponse,
	RehabActionsResolvedResponse,
	RehabCostParams,
	RehabCostResolvedResponse,
	RehabDecisionTree,
	RehabResults,
} from '@Types/rehab.types';

export const getRehabTreesApi = (query: string) => {
	const address = '/rehabTrees' + query;
	return rehabApi.get<ResolvedResponse>(address);
};

export const getRehabTreeApi = (id: string) => {
	const address = `/rehabTree/${id}`;
	return rehabApi.get<GetRehabTreeParams>(address);
};
export const addRehabTreeApi = (tree: RehabDecisionTree) => {
	const address = '/rehabTree';
	return rehabApi.post<AddRehabTreePayload>(address, tree);
};
export const updateRehabTreeApi = (id: string, tree: RehabDecisionTree) => {
	const address = `/rehabTree/${id}`;
	return rehabApi.put(address, tree);
};

export const deleteRehabTreeApi = (id: string) => {
	const address = `/rehabTree/${id}`;
	return rehabApi.delete<GetRehabTreeParams>(address);
};

export const getRehabActionsApi = (treeId: string) => {
	const address = `/rehabActions/${treeId}`;
	return rehabApi.get<RehabActionsResolvedResponse>(address);
};

export const getRehabActionApi = (actionId: string) => {
	const address = `/rehabAction/${actionId}`;
	return rehabApi.get<RehabActionResolvedResponse>(address);
};

export const addRehabActionApi = (params: RehabAction) => {
	const address = '/rehabAction';
	return rehabApi.post(address, params);
};

export const saveRehabActionApi = (params: RehabAction) => {
	const address = `/rehabAction/${params._id}`;
	return rehabApi.put(address, params);
};

export const deleteRehabActionApi = (actionId: string) => {
	const address = `/rehabAction/${actionId}`;
	return rehabApi.delete(address);
};

export const validateRehabTreeApi = (treeId: string) => {
	const address = `/query/rehabTree/validate/${treeId}`;
	return rehabApi.get(address);
};

export const runRehabTreeApi = (treeId: string) => {
	const address = `/query/rehabTree/run/${treeId}`;

	return rehabApi.get(address);
};
export const getRehabResultApi = (treeId: string, query: string) => {
	const address = `/rehabResults/${treeId}`;
	return rehabApi.get<ResolvedResponse>(`${address}${query}`);
};

export const lockRehabActionApi = (rehabResults: RehabResults) => {
	if (rehabResults) {
		const address = `/rehabResults/lock/${rehabResults.treeId}`;

		return rehabApi.put(address, {
			assetIds: [rehabResults.assetId],
			locked: rehabResults.locked,
			modifiedBy: '',
		});
	}
};

export const editRehabActionApi = (rehabResults: RehabResults) => {
	if (rehabResults) {
		const address = `/rehabResults/edit/${rehabResults.treeId}`;

		return rehabApi.put(address, rehabResults);
	}
};

export const updateRehabStatus = (treeId: string, status: string) => {
	const address = `/status/rehabTree/${treeId}`;
	return rehabApi.put(address, { status });
};

export const updateRehabValidationStatus = (treeId: string, status: string) => {
	const address = `/validationStatus/rehabTree/${treeId}`;
	return rehabApi.put(address, { status });
};

export const getRehabTreeStatusApi = (treeId: string) => {
	const address = `/status/rehabTree/${treeId}`;
	return rehabApi.get(address);
};

export const getRehabTreeValidationStatusApi = (treeId: string) => {
	const address = `/validationStatus/rehabTree/${treeId}`;
	return rehabApi.get(address);
};

export const duplicateRehabTreeApi = (treeId: string) => {
	const address = `/query/rehabTree/duplicate/${treeId}`;

	return rehabApi.get(address);
};

export const renameRehabTreeApi = (treeId: string, name: string) => {
	const address = `/rehabTree/${treeId}`;
	return rehabApi.put(address, { newName: name });
};

export const getRehabCostApi = (params: RehabCostParams) => {
	const address = '/rehabCost';
	return rehabApi.post<RehabCostResolvedResponse>(address, {
		treeId: params.treeId,
		actionId: params.actionId,
		assetId: params.assetId,
		assetType: params.assetType,
	});
};

export const updateRehabTreeValidationDataApi = (
	id: string,
	validationData: string[],
) => {
	const address = `/validation/rehabTree/edit/${id}`;
	return rehabApi.put(address, validationData);
};
